import React, { useEffect, useRef, useState, useContext, useLayoutEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { connect, useSelector, useDispatch } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import * as auth from '../_redux/authRedux'
import { login, getTotalLoginFailed } from '../_redux/authCrud'
import ReCAPTCHA from 'react-google-recaptcha'
import GoogleAuthenticator from './GoogleAuthenticator'
import style from '../auth.module.css'
import classNames from 'classnames/bind'
import InvalidDomainDialog from '../../InvalidDomainDialog/InvalidDomainDialog'
import LoginTokenDialog from '../../LoginTokenDialog/LoginTokenDialog'
import { changePasswordForFirstLogin } from '../_redux/authCrud'
import {
  FORCE_LOG_OUT_MESSAGE,
  CHANGE_PASSWORD_FORCE_LOG_OUT_MESSAGE,
  EXPIRED_SESSION_LOGIN_MESSAGE,
  REDIRECT_DEFAULT_COUNTDOWN_TIME_IN_SECONDS,
  ACCOUNT_LOCKED,
  LOGIN_TOKEN_KEY,
  FORCE_LOGOUT_WHEN_DUPLICATE_LOGIN_KEY
} from '../../../../utils/Constants'
import Swal from 'sweetalert2'
import { Alert } from 'react-bootstrap'
import VerifyOTP from './VerifyOTP'
import { Modal } from 'react-bootstrap'
import { TemplateContext } from '../../../../_metronic/layout/_core/TemplateProvider'
import ChangePassword from './ChangePassword'
import LoadingPage from '../../LoadingPage/LoadingPage'
import { env } from '../../../../env'

const cx = classNames.bind(style)
/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

let intervalChangePassword = null
const protocol = env.REACT_APP_PROTOCOL
const INTERVAL_COUNT_DOWN_TIME = 5
function Login(props) {
  const dispatch = useDispatch()
  const history = useHistory()

  const INVALID_LOGIN_TOKEN = 989
  const IS_REQUIRED_GOOGLE_AUTH_WITH_QRCODE = 998
  const IS_REQUIRED_GOOGLE_AUTH_WITHOUT_QRCODE = 997
  const IS_CHANGE_PASSWORD_REQUIRED_RESULT_CODE = [10201, 10004]
  const INVALID_USERNAME = 4033
  const INVALID_TERMINAL = 4030
  const INVALID_PASSWORD = 10002
  const API_LOGIN_ERROR = 999
  const INVALID_DOMAIN = 10238
  const SUCCESS_RESULT_CODE = 0
  const { intl, logout } = props
  const [loading, setLoading] = useState(false)
  const [loadingLoginWithToken, setLoadingLoginWithToken] = useState(false)
  const [resultCode, setResultCode] = useState(0)
  const [isGoogleAuthRequired, setIsGoogleAuthRequired] = useState(false)
  const [isChangePasswordRequired, setIsChangePasswordRequired] = useState(false)
  const [checked, setChecked] = useState(localStorage.getItem('MerchantPortalRememberMeInfo') ? true : false)
  let reCaptchaRef = useRef()
  const [captcha, setCaptcha] = useState({ isShow: false, value: null })
  const [data, setData] = useState()
  const [isLockedUser, setIsLockedUser] = useState(false)
  const [isOpenUnlockUserDialog, setIsOpenUnlockUserDialog] = useState(false)
  const [isSendingUnlockUserEmail, setIsSendingUnlockUserEmail] = useState(false)
  const [isOpenInvalidDomainDialog, setIsOpenInvalidDomainDialog] = useState(false)
  const [isOpenInvalidLoginTokenDialog, setIsOpenInvalidLoginTokenDialog] = useState(false)
  const [redirectDomain, setRedirectDomain] = useState('')
  const [redirectTimeout, setRedirectTimeout] = useState(REDIRECT_DEFAULT_COUNTDOWN_TIME_IN_SECONDS)
  const [intervalCountDownId, setIntervalCountDownId] = useState(0)
  const [isChangePasswordSuccess, setIsChangePasswordSuccess] = useState(false)
  const [intervalCountDownTimeAfterChangePass, setIntervalCountDownTimeAfterChangePass] = useState(
    INTERVAL_COUNT_DOWN_TIME
  )
  const [messageUserLocked, setMessageUserLocked] = useState()
  const [loginToken, setLoginToken] = useState()

  let currentRedirectTimeout = redirectTimeout

  const auth = useSelector(state => state.auth)

  const templateContext = useContext(TemplateContext)

  useEffect(() => {
    getTotalLoginFailed().then(response => {
      const { resultCode, total } = response.data
      if (resultCode === 0) {
        if (total >= 2) {
          setCaptcha({ isShow: true, value: null })
        }
      }
    })
  }, [])

  const handleShowAlert = (message, isSuccess = true) => {
    Swal.fire(isSuccess ? 'Success' : 'Warning', message, isSuccess ? 'success' : 'error').then(result => {
      if (result.isConfirmed || result.isDismissed) {
        handleDismiss()
      }
    })
  }

  useEffect(() => {
    let forceLogOutMessage = window.localStorage.getItem(FORCE_LOG_OUT_MESSAGE)
    if (forceLogOutMessage) {
      document.documentElement.setAttribute('data-color-scheme', 'Light')
      window.localStorage.removeItem(FORCE_LOG_OUT_MESSAGE)
      handleShowAlert(forceLogOutMessage, false)
    }

    let changePasswordForceLogOutMessage = window.localStorage.getItem(CHANGE_PASSWORD_FORCE_LOG_OUT_MESSAGE)
    if (changePasswordForceLogOutMessage) {
      document.documentElement.setAttribute('data-color-scheme', 'Light')
      window.localStorage.removeItem(CHANGE_PASSWORD_FORCE_LOG_OUT_MESSAGE)
      handleShowAlert(changePasswordForceLogOutMessage, true)
    }

    let expiredLoginMessage = window.localStorage.getItem(EXPIRED_SESSION_LOGIN_MESSAGE)
    if (expiredLoginMessage) {
      document.documentElement.setAttribute('data-color-scheme', 'Light')
      window.localStorage.removeItem(EXPIRED_SESSION_LOGIN_MESSAGE)
      handleShowAlert(expiredLoginMessage, false)
    }

    if (window.localStorage.getItem(FORCE_LOGOUT_WHEN_DUPLICATE_LOGIN_KEY)) {
      const data = JSON.parse(window.localStorage.getItem(FORCE_LOGOUT_WHEN_DUPLICATE_LOGIN_KEY))
      if (data.forceLogout) {
        handleShowAlert(intl.formatMessage({ id: 'FORCE.LOGOUT.MESSAGE' }), false)
      }
    }
    window.localStorage.removeItem(FORCE_LOGOUT_WHEN_DUPLICATE_LOGIN_KEY)
  }, [])

  useEffect(() => {
    if (auth.message) {
      handleShowAlert(auth.message, false)
    }
  }, [auth.message])

  useEffect(() => {
    if (isOpenInvalidDomainDialog) {
      startTimer()
    } else {
      clearInterval(intervalCountDownId)
      setIntervalCountDownId(0)
    }
  }, [isOpenInvalidDomainDialog])

  useEffect(() => {
    currentRedirectTimeout = redirectTimeout
    if (redirectTimeout === 0) {
      closeInvalidDomainDialog()
      window.open(protocol + redirectDomain + '/auth/login?loginToken=' + loginToken, '_self')
    }
  }, [redirectTimeout])

  const LoginSchema = Yup.object().shape({
    merchantId: Yup.string()
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD'
        })
      )
      .trim(
        intl.formatMessage(
          {
            id: 'AUTH.VALIDATION.INVALID_WHITESPACE'
          },
          { name: 'The Merchant ID' }
        )
      )
      .strict(true),
    username: Yup.string()
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD'
        })
      )
      .trim(
        intl.formatMessage(
          {
            id: 'AUTH.VALIDATION.INVALID_WHITESPACE'
          },
          { name: 'The Username' }
        )
      )
      .strict(true),
    password: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD'
      })
    )
  })

  const enableLoading = () => {
    setLoading(true)
  }

  const disableLoading = () => {
    setLoading(false)
  }

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid'
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid'
    }

    return ''
  }

  const checkValidDomainDialog = redirectDomain => {
    if (redirectDomain) {
      if (window.location.host !== redirectDomain) {
        setRedirectDomain(redirectDomain)
        return false
      }
      return true
    }
    return false
  }

  const closeInvalidDomainDialog = () => {
    setIsOpenInvalidDomainDialog(false)
    setRedirectDomain('')
    setRedirectTimeout(REDIRECT_DEFAULT_COUNTDOWN_TIME_IN_SECONDS)
  }

  const startTimer = () => {
    if (intervalCountDownId == 0 && redirectTimeout > 0) {
      let timer = setInterval(redirectCountDown, 1000)
      setIntervalCountDownId(timer)
    }
  }

  const redirectCountDown = () => {
    let remaining = currentRedirectTimeout - 1
    currentRedirectTimeout = remaining
    setRedirectTimeout(remaining)
  }

  const formik = useFormik({
    initialValues: {
      merchantId:
        localStorage.getItem('MerchantPortalRememberMeInfo') === null
          ? ''
          : JSON.parse(localStorage.getItem('MerchantPortalRememberMeInfo')).merchantId,
      username:
        localStorage.getItem('MerchantPortalRememberMeInfo') === null
          ? ''
          : JSON.parse(localStorage.getItem('MerchantPortalRememberMeInfo')).username,
      password: ''
    },
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      if (captcha.isShow) {
        if (captcha.value === null) {
          disableLoading()
          setResultCode(9999)
          setStatus(
            intl.formatMessage({
              id: 'AUTH.CAPTCHA.VALIDATION.REQUIRED'
            })
          )
          setSubmitting(false)
          return
        }
      }
      enableLoading()
      handleLogin(formik, null)
    }
  })
  const handleLogin = ({ values, setSubmitting, setStatus }, loginTokenRedirect) => {
    const getMessageCode = (resultCode, email) => {
      switch (resultCode) {
        case ACCOUNT_LOCKED: {
          if (email == null || email == '') {
            return 'AUTH.VALIDATION.ACCOUNT_LOCKED'
          } else {
            return 'AUTH.VALIDATION.ACCOUNT_LOCKED_HAVE_EMAIL'
          }
        }

        default:
          return 'AUTH.VALIDATION.INVALID_LOGIN'
      }
    }

    const getMessage = (resultCode, email) => {
      switch (resultCode) {
        case ACCOUNT_LOCKED: {
          if (email == null || email == '') {
            return {
              link: (
                <Link key={1} className={cx('hyperlink-message')} to="/auth/contact-support">
                  {intl.formatMessage({
                    id: 'AUTH.CONTACT.US.CONTACT_SUPPORT'
                  })}
                </Link>
              )
            }
          } else {
            return {
              link: (
                <span key={2} onClick={() => openUnlockUserDialog()} className={cx('hyperlink-message')} to="#">
                  here
                </span>
              )
            }
          }
        }

        default:
          return {
            link: (
              <Link key={1} className={cx('hyperlink-message')} to="/auth/contact-support">
                {intl.formatMessage({
                  id: 'AUTH.CONTACT.US.CONTACT_SUPPORT'
                })}
              </Link>
            )
          }
      }
    }

    const openUnlockUserDialog = () => {
      setIsOpenUnlockUserDialog(true)
    }

    setTimeout(() => {
      login(values.merchantId, values.username, values.password, null, loginTokenRedirect)
        .then(response => {
          const payload = {
            merchantId: values.merchantId,
            username: values.username
          }
          const resultCode = response?.data?.resultCode
          const domain = response?.data?.redirectDomain

          let validDomain = checkValidDomainDialog(domain)
          if (validDomain && resultCode === SUCCESS_RESULT_CODE) {
            let accessToken = response.headers['authorization'].replace('Bearer ', '')
            props.login(accessToken)
          } else if (!validDomain && resultCode === SUCCESS_RESULT_CODE) {
            setResultCode(INVALID_DOMAIN)
            setIsOpenInvalidDomainDialog(true)
            setStatus(
              intl.formatMessage({
                id: 'ACCESS_DOMAIN_DENIED_MESSAGE'
              })
            )
            setLoginToken(response?.data?.loginToken)
          }

          if (checked) {
            localStorage.setItem('MerchantPortalRememberMeInfo', JSON.stringify(payload))
          }
          if (captcha.isShow) {
            window.grecaptcha.reset()
            setCaptcha({ ...captcha, value: null })
          }
          disableLoading()
          setLoadingLoginWithToken(false)
          setSubmitting(false)
          setIsLockedUser(false)
        })
        .catch(({ response, message }) => {
          let errorMessage = message
          const resultCode = response?.data?.resultCode
          const countFailed = response?.data?.countFailed
          const domain = response?.data?.redirectDomain
          let validDomain = checkValidDomainDialog(domain)
          setResultCode(resultCode)
          if (captcha.isShow) {
            window.grecaptcha.reset()
            setCaptcha({ ...captcha, value: null })
          }

          if (countFailed >= 2) {
            setCaptcha({ isShow: true, value: null })
          }

          if (INVALID_LOGIN_TOKEN === resultCode || (loginTokenRedirect && ACCOUNT_LOCKED === resultCode)) {
            setIsOpenInvalidLoginTokenDialog(true)
            if (loginTokenRedirect && ACCOUNT_LOCKED === resultCode) {
              setMessageUserLocked(
                intl.formatMessage(
                  {
                    id: getMessageCode(resultCode, response.data.email)
                  },
                  getMessage(resultCode, response.data.email)
                )
              )
            }
          } else if (validDomain && ACCOUNT_LOCKED === resultCode) {
            setStatus(
              intl.formatMessage(
                {
                  id: getMessageCode(resultCode, response.data.email)
                },
                getMessage(resultCode, response.data.email)
              )
            )
          } else if (!response?.status) {
            setResultCode(API_LOGIN_ERROR)
            setStatus(errorMessage)
          }

          if (validDomain) {
            switch (resultCode) {
              case IS_REQUIRED_GOOGLE_AUTH_WITHOUT_QRCODE:
              case IS_REQUIRED_GOOGLE_AUTH_WITH_QRCODE: {
                setData(response?.data)
                setIsGoogleAuthRequired(true)
                setIsLockedUser(false)
                break
              }
              case INVALID_TERMINAL:
              case INVALID_USERNAME:
              case INVALID_PASSWORD: {
                setStatus(
                  intl.formatMessage(
                    {
                      id: 'AUTH.VALIDATION.INVALID_LOGIN'
                    },
                    getMessage(resultCode, response.data.email)
                  )
                )
                break
              }
              case IS_CHANGE_PASSWORD_REQUIRED_RESULT_CODE[0]:
              case IS_CHANGE_PASSWORD_REQUIRED_RESULT_CODE[1]: {
                setIsChangePasswordRequired(true)
                break
              }
              default: {
                break
              }
            }
          } else {
            if (INVALID_TERMINAL === resultCode || INVALID_USERNAME === resultCode || INVALID_PASSWORD === resultCode) {
              setStatus(
                intl.formatMessage(
                  {
                    id: 'AUTH.VALIDATION.INVALID_LOGIN'
                  },
                  getMessage(resultCode, response.data.email)
                )
              )
            } else if (resultCode && resultCode !== API_LOGIN_ERROR && resultCode !== INVALID_LOGIN_TOKEN) {
              setIsOpenInvalidDomainDialog(true)
              setLoginToken(response?.data?.loginToken)
            }
          }

          disableLoading()
          setLoadingLoginWithToken(false)
          setSubmitting(false)
        })
    }, 1000)
  }

  useEffect(() => {
    const urlPage = window.location.search
    const params = new URLSearchParams(urlPage)
    const loginTokenRedirect = params.get(LOGIN_TOKEN_KEY)
    if (loginTokenRedirect) {
      setLoadingLoginWithToken(true)
      handleLogin(formik, loginTokenRedirect)
    }
  }, [])

  useEffect(() => {
    let isCleanUp = true
    if (isCleanUp) {
      if (auth.verifyUnlock?.isSendVerifyUnlockSuccess) {
        setCaptcha({ isShow: false, value: null })
        setIsLockedUser(true)
        handleDismiss()
        setIsOpenUnlockUserDialog(false)
        setIsSendingUnlockUserEmail(false)
      }
    }
    return () => {
      isCleanUp = false
    }
  }, [auth.verifyUnlock])

  useEffect(() => {
    const merchantId = JSON.parse(localStorage.getItem('MerchantPortalRememberMeInfo'))?.merchantId
    const username = JSON.parse(localStorage.getItem('MerchantPortalRememberMeInfo'))?.username
    const merchantIdPayload = formik.values.merchantId
    const usernamePayload = formik.values.username
    let isChecked = !checked
    if (!isChecked && (merchantIdPayload !== merchantId || usernamePayload !== username)) {
      const payload = {
        merchantId: formik.values.merchantId,
        username: formik.values.username
      }
      localStorage.setItem('MerchantPortalRememberMeInfo', JSON.stringify(payload))
    }
  }, [formik.values.merchantId, formik.values.username])

  const handleCheckedOnchange = () => {
    let isChecked = !checked
    const payload = {
      merchantId: formik.values.merchantId,
      username: formik.values.username
    }
    setChecked(isChecked)
    if (!isChecked) {
      localStorage.removeItem('MerchantPortalRememberMeInfo')
    } else {
      localStorage.setItem('MerchantPortalRememberMeInfo', JSON.stringify(payload))
    }
  }

  const handleDismiss = () => {
    formik.setStatus(null)
    formikChangePassword.setStatus(null)
    dispatch(logout())
  }

  const onChangeCaptcha = value => {
    setCaptcha({ ...captcha, value: value })
  }
  const handleClose = () => {
    setIsOpenUnlockUserDialog(false)
  }

  const handleSendUnlockCode = () => {
    setIsSendingUnlockUserEmail(true)
    const urlPage = window.location.search
    const params = new URLSearchParams(urlPage)
    const payload = { userName: formik.values.username, loginToken: params.get(LOGIN_TOKEN_KEY) }
    props.sendUnlockCodeLoading(payload)
    setIsOpenInvalidLoginTokenDialog(false)
  }

  const initialValueChangePassword = {
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: ''
  }

  const changePasswordSchema = Yup.object().shape({
    oldPassword: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD'
      })
    ),
    newPassword: Yup.string()
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD'
        })
      )
      .matches(
        /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*^\S*$)(?=.*[!@#&()?[\{\}\]\:\;\'\,\?\/\*\~$^\+\=\<\>]).{8,50}$/,
        'Password must be 8-50 characters long and contain at least one upper case letter, one lower case letter, one numeric character, and one special character with no spaces.'
      ),
    confirmNewPassword: Yup.string()
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD'
        })
      )
      .oneOf([Yup.ref('newPassword'), null], "Confirm new password doesn't match")
  })

  const formikChangePassword = useFormik({
    initialValues: initialValueChangePassword,
    validationSchema: changePasswordSchema,
    onSubmit: (values, { setSubmitting, setStatus, resetForm }) => {
      const urlPage = window.location.search
      const params = new URLSearchParams(urlPage)
      const payload = {
        ...values,
        terminalId: formik.values.merchantId,
        userName: formik.values.username,
        loginToken: params.get(LOGIN_TOKEN_KEY)
      }
      changePasswordForFirstLogin(payload)
        .then(res => {
          const { resultCode, resultDescription } = res.data
          if (resultCode === SUCCESS_RESULT_CODE) {
            setIsChangePasswordSuccess(true)
            formik.setStatus(null)
            resetForm()
            setCaptcha({ isShow: false, value: null })
          }
          setStatus({ resultCode, resultDescription })
        })
        .catch(({ message }) => {
          setStatus(message)
        })
        .finally(() => {
          setSubmitting(false)
        })
    }
  })

  useLayoutEffect(() => {
    if (isChangePasswordSuccess) {
      startCountDownAfterChangePass()
    }
  }, [isChangePasswordSuccess])

  useLayoutEffect(() => {
    if (intervalCountDownTimeAfterChangePass === 0) {
      stopCountDownAfterChangePass()
    }
  }, [intervalCountDownTimeAfterChangePass])

  const startCountDownAfterChangePass = () => {
    intervalChangePassword = setInterval(() => {
      setIntervalCountDownTimeAfterChangePass(prev => prev - 1)
    }, 1000)
  }

  const stopCountDownAfterChangePass = () => {
    clearInterval(intervalChangePassword)
    setIsChangePasswordRequired(false)
    setIntervalCountDownTimeAfterChangePass(INTERVAL_COUNT_DOWN_TIME)
    formik.setFieldValue('password', '')
    window.open(protocol + window.location.host + '/auth/login', '_self')
  }

  useEffect(() => {
    if (localStorage.getItem('isImpersonate')) {
      localStorage.removeItem('isImpersonate')
    }
  }, [])

  return (
    <>
      {loadingLoginWithToken && <LoadingPage />}

      <InvalidDomainDialog
        isOpenModal={isOpenInvalidDomainDialog}
        seconds={redirectTimeout}
        countDownTime={REDIRECT_DEFAULT_COUNTDOWN_TIME_IN_SECONDS}
        redirectDomain={redirectDomain}
        onCancel={closeInvalidDomainDialog}
        loginToken={loginToken}
      />

      <LoginTokenDialog
        isOpenModal={isOpenInvalidLoginTokenDialog}
        resultCode={resultCode}
        message={messageUserLocked}
        intl={intl}
      />

      {isOpenUnlockUserDialog && (
        <Modal centered show={isOpenUnlockUserDialog} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Unlock User Account</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to send an unlock email to your account?</Modal.Body>
          <Modal.Footer>
            <button onClick={handleSendUnlockCode} className={cx('button', 'btn-primary', 'btn', 'btn-lg')}>
              <span>Confirm</span>
              {isSendingUnlockUserEmail && <span className="ml-3 spinner spinner-white"></span>}
            </button>
            <button onClick={handleClose} className={cx('button', 'btn-primary', 'btn', 'btn-lg')}>
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
      )}
      {isGoogleAuthRequired ? (
        <>
          <div
            className={cx(
              'content-body',
              'flex-direction-column',
              resultCode === IS_REQUIRED_GOOGLE_AUTH_WITHOUT_QRCODE ? ['width-400px'] : 'padding-10-20'
            )}
          >
            <div
              className={
                resultCode === IS_REQUIRED_GOOGLE_AUTH_WITHOUT_QRCODE ? 'text-center' : 'text-center mb-10 mb-lg-10'
              }
            >
              <Link to="/" className="flex-column-auto mt-5">
                <div className="max-h-100px logo"></div>
              </Link>
              <h3 className="font-size-h3">
                <FormattedMessage id="GOOGLE.AUTHENTICATOR.TITLE" />
              </h3>
            </div>
            <GoogleAuthenticator
              data={data}
              formik={formik}
              checked={checked}
              props={props}
              setIsGoogleAuthRequired={setIsGoogleAuthRequired}
              setCaptcha={setCaptcha}
              resultCode={resultCode}
            />
          </div>
        </>
      ) : isLockedUser ? (
        <VerifyOTP formikLogin={formik} setIsLockedUser={setIsLockedUser} handleLogin={handleLogin} />
      ) : isChangePasswordRequired ? (
        <>
          <div className="login-form login-signin" id="kt_login_signin_form">
            <div className={'text-center mb-10 mb-lg-10'}>
              <Link to="/" className="flex-column-auto mt-5">
                <div className="max-h-100px logo"></div>
              </Link>
              <h3 className="font-size-h3">
                <FormattedMessage id="CHANGE.PASSWORD.TITLE" />
              </h3>
            </div>
            <div className={cx('change-password-notice-message')}>
              <p>Please fill the following information to change your password in your first time login</p>
            </div>
            {formikChangePassword.status && (
              <Alert
                variant={formikChangePassword.status.resultCode === 0 ? 'success' : 'danger'}
                onClose={handleDismiss}
                dismissible
              >
                {formikChangePassword.status.resultCode === 0 ? (
                  <FormattedMessage
                    id="CHANGE.PASSWORD.SUCCESS.MESSAGE"
                    values={{
                      seconds: (
                        <>
                          <b>{intervalCountDownTimeAfterChangePass}</b>
                        </>
                      ),
                      here: (
                        <>
                          <p
                            onClick={() => {
                              stopCountDownAfterChangePass()
                            }}
                            className={cx('click-here')}
                          >
                            here
                          </p>
                        </>
                      )
                    }}
                  />
                ) : (
                  formikChangePassword.status.resultDescription
                )}
              </Alert>
            )}
            <ChangePassword intl={intl} formik={formikChangePassword} getInputClasses={getInputClasses} />
          </div>
        </>
      ) : (
        <div className="login-form login-signin" id="kt_login_signin_form">
          {/* begin::Head */}
          <div className="text-center mb-10 mb-lg-20">
            <Link to="/" className="flex-column-auto mt-5">
              <div className="max-h-100px logo"></div>
            </Link>
            {templateContext?.portalName ? (
              <h3 className="font-size-h1">
                <FormattedMessage id="PORTAL.NAME" values={{ name: templateContext.portalName }} />
              </h3>
            ) : (
              <h3 className="font-size-h1">
                <FormattedMessage id="AUTH.LOGIN.TITLE" />
              </h3>
            )}
          </div>
          {/* end::Head */}

          {/*begin::Form*/}
          <form onSubmit={formik.handleSubmit} className="form fv-plugins-bootstrap fv-plugins-framework">
            {formik.status && (
              <Alert variant={resultCode === 0 ? 'success' : 'danger'} onClose={handleDismiss} dismissible>
                {formik.status}
              </Alert>
            )}
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Merchant ID"
                disabled={formik.isSubmitting}
                type="text"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses('merchantId')}`}
                name="merchantId"
                {...formik.getFieldProps('merchantId')}
              />
              {formik.touched.merchantId && formik.errors.merchantId ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.merchantId}</div>
                </div>
              ) : null}
            </div>

            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Username"
                disabled={formik.isSubmitting}
                type="text"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses('username')}`}
                name="username"
                {...formik.getFieldProps('username')}
              />
              {formik.touched.username && formik.errors.username ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.username}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Password"
                disabled={formik.isSubmitting}
                type="password"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses('password')}`}
                name="password"
                {...formik.getFieldProps('password')}
              />
              {formik.touched.password && formik.errors.password ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.password}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group">
              <label className={`checkbox checkbox-custom`}>
                <input
                  disabled={formik.isSubmitting}
                  type="checkbox"
                  name="Checkbox"
                  checked={checked}
                  onChange={handleCheckedOnchange}
                />
                <span></span>
                Remember Me
              </label>
            </div>
            {captcha.isShow && (
              <div className="form-group fv-plugins-icon-container">
                <ReCAPTCHA
                  className={cx('captcha-body')}
                  ref={reCaptchaRef}
                  size="normal"
                  sitekey={env.REACT_APP_GOOGLE_CAPTCHA_CLIENT_KEY}
                  onChange={onChangeCaptcha}
                />
              </div>
            )}
            <div className="form-group fv-plugins-icon-container">
              <button
                id="kt_login_signin_submit"
                type="submit"
                disabled={loading}
                className={`btn btn-lg btn-primary btn-block btn-primary-custom`}
              >
                <span>Sign In</span>
                {loading && <span className="ml-3 spinner spinner-white"></span>}
              </button>
            </div>
            <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
              <Link
                to={formik.isSubmitting ? '#' : '/auth/forgot-password'}
                className="text-dark-50 text-hover-primary-custom my-3 mr-2"
                id="kt_login_forgot"
              >
                <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
              </Link>
              <Link
                to={formik.isSubmitting ? '#' : '/auth/contact-support'}
                className="text-dark-50 text-hover-primary-custom my-3 mr-2"
                id="kt_login_contact_support"
              >
                <FormattedMessage id="AUTH.GENERAL.CONTACT_SUPPORT_BUTTON" />
              </Link>
            </div>
          </form>
          {/*end::Form*/}
        </div>
      )}
    </>
  )
}

export default injectIntl(connect(null, auth.actions)(Login))
