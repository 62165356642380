import React, { Suspense, lazy, useState, useEffect, useLayoutEffect } from 'react'
import { Redirect, Switch, Route, useHistory } from 'react-router-dom'
import { LayoutSplashScreen, ContentRoute } from '../_metronic/layout'
import { BuilderPage } from './pages/BuilderPage'
import { MyPage } from './pages/MyPage'
import GoogleAuthenticator from './modules/Auth/pages/GoogleAuthenticator'
import CompanyDetails from './pages/companyDetails/index'
import Dashboard from './pages/dashboard/index'
import SettlementEarnings from './pages/settlementEarnings/index'
import PayoutTransaction from './pages/payoutTransaction'
import Reports from './pages/reports/index'
import PinOrder from './pages/pinOrder/index'
import UserManagement from './pages/userManagement/index'
import Resources from './pages/resources/index'
import { ContactUs } from './pages/contactUs/index'
import UserProfile from './pages/userProfile/index'
import ProductRedeem from './pages/productRedeem/index'
import PerformanceReport from './pages/portalManagement/components/performanceReport/index'
import ResourceManagement from './pages/portalManagement/components/resourceManagement/index'
const GoogleMaterialPage = lazy(() => import('./modules/GoogleMaterialExamples/GoogleMaterialPage'))
const ReactBootstrapPage = lazy(() => import('./modules/ReactBootstrapExamples/ReactBootstrapPage'))
const ECommercePage = lazy(() => import('./modules/ECommerce/pages/eCommercePage'))
const EnginePage = lazy(() => import('./modules/Engine/pages/enginePage'))
const EmailAddressForm = lazy(() => import('./modules/Auth/pages/EmailAddressForm'))
const EmailConfirmation = lazy(() => import('./modules/Auth/pages/EmailConfirmation'))
import { IdleTimerProvider } from 'react-idle-timer'
import SessionTimeoutDialog from './modules/SessionTimeoutDialog/SessionTimeoutDialog'
import store from '../redux/store'
import { actionTypes, actions } from './modules/Auth/_redux/authRedux'
import { actionTypes as actionTypesThemeMode, actions as actionsThemeMode } from '../redux/basePageRedux'
import { logout } from './modules/Auth/_redux/authCrud'
import { connect, useSelector } from 'react-redux'
import { KTCookie } from '../_metronic/_assets/js/components/cookie.js'
import { IS_ENABLE_PAYOUT_KEY } from '../utils/Constants'
import { env } from '../env'
import { useIntl } from 'react-intl'
import CreditLimits from './pages/creditLimits/index'
let countDown = null
let countDownTime = env.REACT_APP_SESSION_COUNTDOWN_TIME_EXPIRES
const timeForASession = env.REACT_APP_TIME_FOR_A_SESSION * 1000
const BasePage = ({ expireLogout, themeModeLoading, theme, resetStore }) => {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [seconds, setSeconds] = useState(countDownTime)
  const { user } = useSelector(state => state.auth)
  const history = useHistory()
  const onIdle = () => {
    setIsOpenModal(true)
    countDown = setInterval(() => {
      if (countDownTime > 0) {
        countDownTime = countDownTime - 1
        setSeconds(countDownTime)
      } else {
        clearInterval(countDown)
        setIsOpenModal(false)
        expireLogout()
        document.documentElement.setAttribute('data-color-scheme', 'light')
        countDownTime = env.REACT_APP_SESSION_COUNTDOWN_TIME_EXPIRES
      }
    }, 1000)
  }

  useEffect(() => {
    var themeMode = KTCookie.getCookie('theme_mode')
    if (themeMode && themeMode !== 'Dark' && themeMode !== 'Light') {
      themeMode = 'Light'
    }

    themeModeLoading(themeMode)
    KTCookie.setCookie('theme_mode', themeMode)
  }, [])

  useLayoutEffect(() => {
    document.documentElement.setAttribute('data-color-scheme', theme === 'Dark' ? 'dark' : 'light')
  }, [theme])

  const onContinue = () => {
    clearInterval(countDown)
    setIsOpenModal(false)
    countDownTime = env.REACT_APP_SESSION_COUNTDOWN_TIME_EXPIRES
    setSeconds(countDownTime)
  }

  const onLogout = () => {
    clearInterval(countDown)
    countDownTime = env.REACT_APP_SESSION_COUNTDOWN_TIME_EXPIRES
    setSeconds(countDownTime)
    logout()
      .then(res => {})
      .catch(error => {})
      .finally(() => {
        store.dispatch({ type: actionTypes.Logout })
        document.documentElement.setAttribute('data-color-scheme', 'Light')
        resetStore()
      })
  }
  useEffect(() => {
    const resultCodes = [10221, 10222, 10212]
    const resultCode = user?.resultCode
    if (resultCodes.includes(resultCode)) {
      history.push('/email-address-required')
    }
  }, [user?.resultCode])

  const intl = useIntl()

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      {/* When the session has expired, a Dialog will be displayed */}
      <IdleTimerProvider timeout={timeForASession} onIdle={onIdle}>
        <SessionTimeoutDialog
          isOpenModal={isOpenModal}
          seconds={seconds}
          onContinue={onContinue}
          onLogout={onLogout}
          sessionCountDownTimeExprires={env.REACT_APP_SESSION_COUNTDOWN_TIME_EXPIRES}
        />
      </IdleTimerProvider>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={Dashboard} />
        <ContentRoute path="/company-details" component={CompanyDetails} />
        <ContentRoute path="/weekly-transaction-summary" component={SettlementEarnings} />
        <ContentRoute
          path="/payout-transaction"
          role={IS_ENABLE_PAYOUT_KEY}
          roleDescription={intl.formatMessage({ id: 'SIDE.BAR.MENU.TITLE.PAYOUT_TRANSACTION' })}
          component={PayoutTransaction}
        />
        <ContentRoute path="/reports" component={Reports} />
        <ContentRoute
          path="/pin-order"
          role="SCP.EVDS.PIN.BATCH.FILE.LIST_R"
          roleDescription={intl.formatMessage({ id: 'SIDE.BAR.MENU.TITLE.PIN_ORDER' })}
          component={PinOrder}
        />
        <ContentRoute
          path="/credit-limits"
          role="SCP.TTI.USER.LIMIT.TEMPLATE.ADMIN_R"
          roleDescription={intl.formatMessage({ id: 'SIDE.BAR.MENU.TITLE.CREDIT_LIMIT' })}
          component={CreditLimits}
        />
        <ContentRoute
          path="/user-management"
          role="SCP.TTI.ADMIN.USER_R"
          roleDescription={intl.formatMessage({ id: 'SIDE.BAR.MENU.TITLE.USER_MANAGEMENT' })}
          component={UserManagement}
        />
        <ContentRoute path="/resources" component={Resources} />
        <ContentRoute path="/contact-us" component={ContactUs} />
        <ContentRoute
          path="/product-redeem"
          role="SCP.EVDS.PRODUCT.REDEMPTION_R"
          roleDescription={intl.formatMessage({ id: 'SIDE.BAR.MENU.TITLE.PRODUCT_VALIDATE' })}
          component={ProductRedeem}
        />
        <ContentRoute path="/profile" component={UserProfile} />
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/my-page" component={MyPage} />
        <ContentRoute path="/google-authentication" component={GoogleAuthenticator} />
        <ContentRoute path="/auth/email-address-required" component={EmailAddressForm} />
        <ContentRoute path="/auth/email-confirmation" component={EmailConfirmation} />
        <ContentRoute
          path="/portal-management/performance-report"
          component={PerformanceReport}
          roleDescription={intl.formatMessage({ id: 'SIDE.BAR.MENU.TITLE.ACCOUNT_MANAGER' })}
          role="SCP.FLEX.ACCOUNT.MANAGER_R"
        />
        <ContentRoute
          path="/portal-management/resource-management"
          component={ResourceManagement}
          roleDescription={intl.formatMessage({ id: 'SIDE.BAR.MENU.TITLE.ACCOUNT_MANAGER' })}
          role="SCP.FLEX.ACCOUNT.MANAGER_R"
        />
        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/e-commerce" component={ECommercePage} />
        <ContentRoute path="/engine" component={EnginePage} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  )
}

const mapStateToProps = state => ({
  theme: state.themeMode?.themeMode?.theme
})

export default connect(mapStateToProps, { ...actions, ...actionsThemeMode })(BasePage)
