import { merchantApiClient } from '../../../../redux'
import axios from 'axios'
import { KTCookie } from '../../../../_metronic/_assets/js/components/cookie'

export const LOGIN_URL = 'api/auth/login'
export const REGISTER_URL = 'api/auth/register'
export const REQUEST_PASSWORD_URL = 'api/auth/request-reset-password'
export const REQUEST_CONTACT_SUPPORT_URL = 'api/auth/contact-support'
export const REQUEST_VALIDATE_RESET_PASSWORD_TOKEN_URL = 'api/auth/validate-reset-password-token?token='
export const REQUEST_RESET_PASSWORD_URL = 'api/auth/reset-password'
export const PROFILE_URL = 'api/v1/profile'
export const TOTAL_LOGIN_FAILED = 'api/auth/total-login-failed'
export const LOGOUT_URL = 'api/v1/logout'
export const VERIFY_EMAIL_URL = 'api/v1/verify-email'
export const CONFIRM_EMAIL_URL = 'api/v1/confirm-email'
export const SEND_UNLOCK_CODE_URL = 'api/user-locked/send-unlock-code'
export const VERIFY_UNLOCK_CODE_URL = 'api/user-locked/verify-unlock-code'
export const CHANGE_PASSWORD_URL = 'api/auth/change-password'

export const ME_URL = 'api/me'

export function login(merchantId, username, password, pinCode, loginToken) {
  return merchantApiClient.post(LOGIN_URL, { merchantId, username, password, pinCode, loginToken })
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password })
}

export function requestPassword(username, email) {
  return merchantApiClient.post(REQUEST_PASSWORD_URL, { username, email })
}

export function requestValidateResetPasswordToken(token) {
  return merchantApiClient.get(REQUEST_VALIDATE_RESET_PASSWORD_TOKEN_URL + token)
}

export function requestResetPassword(password, confirmPassword, token) {
  return merchantApiClient.post(REQUEST_RESET_PASSWORD_URL, { password, confirmPassword, token })
}

export function requestContactSupport(email, subject, content, files, transId = null) {
  let formData = new FormData()

  const config = {
    headers: { 'content-type': 'multipart/form-data' }
  }

  formData.append('email', email)
  formData.append('subject', subject)
  formData.append('content', content)
  transId && formData.append('transId', transId)
  files.forEach(file => {
    formData.append('files', file)
  })

  return merchantApiClient.post(REQUEST_CONTACT_SUPPORT_URL, formData, config)
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return merchantApiClient.get(PROFILE_URL)
}

export function getTotalLoginFailed() {
  return merchantApiClient.get(TOTAL_LOGIN_FAILED)
}

export function logout() {
  return merchantApiClient.get(LOGOUT_URL).finally(() => {
    KTCookie.setCookie('theme_mode', '', {
      'max-age': -1
    })
  })
}

export function sendOtpToEmail(payload) {
  const { email } = payload
  return merchantApiClient.post(VERIFY_EMAIL_URL, { email })
}

export function confirmEmail(payload) {
  const { otp, email } = payload
  return merchantApiClient.post(CONFIRM_EMAIL_URL, { otp, email })
}

export function sendOtpToEmailForUnLockUser(payload) {
  const { userName, loginToken } = payload
  return merchantApiClient.post(SEND_UNLOCK_CODE_URL, { userName, loginToken })
}

export function verifyOtpForUnlockUser(payload) {
  const { otp, email } = payload
  return merchantApiClient.put(VERIFY_UNLOCK_CODE_URL, { otp, email })
}

export function changePasswordForFirstLogin(payload) {
  return merchantApiClient.put(CHANGE_PASSWORD_URL, payload)
}
